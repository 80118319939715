import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Startled from "../images/startled.svg"
import { Section } from "../components/sections/sections.js"
import { SectionTitle, SectionDescription } from "../components/typography.js"
import { SubmitNext } from "../components/elements/buttons.js"
const Img = styled.img`
  display: block;
  width: 30%;
  height: auto;
  margin: 0 auto;
`

const St = styled(SectionTitle)`
  font-size: 25px;
`
const BtnWrap = styled.div`
  margin: 25px auto 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 50%;
`
// const SubmitNext = SubmitNext
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <Img src={Startled} />
      <St center={"center"}>Yowza! URL doesn't exist</St>
      <SectionDescription center={"center"}>
        <br />
        It looks like you entered a URL that doesn't exist. <br />
        ... But, we've give you some links below; maybe they're somewhat useful?
      </SectionDescription>
      <BtnWrap>
        <Link to="/">
          <SubmitNext backgroundColor={"#000"}>Home</SubmitNext>
        </Link>
        <Link to="/app/login">
          <SubmitNext backgroundColor={"#000"}>Login</SubmitNext>
        </Link>
        <Link to="/app/profile">
          <SubmitNext backgroundColor={"#000"}>Profile</SubmitNext>
        </Link>
      </BtnWrap>
    </Section>
  </Layout>
)

export default NotFoundPage
